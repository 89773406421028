
import { computed, defineComponent, PropType, ref, watch } from '@vue/composition-api'
import { mdiCalendar, mdiClock, mdiComment } from '@mdi/js'
import {
  required,
  positiveNumber,
  ValidationForm,
  hoursBudgetValidation,
  planActivitiesFrom,
  planActivitiesTo,
} from '@/utils/validation'
import { useGetUsersBasic } from '@/api/users'
import { Plan, PlanInput, Project } from '@/api/interfaces'
import DateInput from '@/components/common/date-input.vue'
import CurrencyInput from '@/components/common/currency-input.vue'
import { useAuthGetters } from '@/store/modules/auth/useAuthModule'
import { Rights } from '@/api/rights'
import { useProjectRepository } from '@/api/repository/project-repository'
import { useTeamRepository } from '@/api/repository/team-repository'
import { isIdentifiable } from '@/api/interfaces/identifiable'
import { useFormatUser } from '@/utils/format/format-user'
import { useActivityRepository } from '@/api/repository/activity-repository'
import { isOfTypeWithValue } from '@/utils/isOfTypeWithValue'

export default defineComponent({
  name: 'plan-dialog',
  components: {
    DateInput,
    CurrencyInput,
  },
  props: {
    item: {
      type: Object as PropType<PlanInput | Plan>,
      required: true,
    },
    project: {
      type: Object as PropType<Project | null>,
      default: null,
    },
    isNewPlan: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'save'],
  setup: (props, { attrs, emit }) => {
    const { hasRight, projectsAsManager } = useAuthGetters()
    const form = ref<ValidationForm | undefined>()
    const formIsValid = ref(false)
    const itemInternal = ref<PlanInput | Plan>({ ...props.item })

    const selectedProject = computed(() =>
      projects.value.find((project) => project.id === itemInternal.value.projectId)
    )

    const { getUsersBasic, data: usersBasic } = useGetUsersBasic()

    const selectedUser = computed(() => usersBasic.value.find((user) => user.id === itemInternal.value.userId))

    watch(selectedUser, (value) => {
      // return if price already set
      if (itemInternal.value.costs !== 0) return
      if (value?.price == null) return
      itemInternal.value.costs = value.price
    })

    const projectRepository = useProjectRepository()
    const { getAll: getAllProjects, data: projects } = projectRepository.useGetAll()

    const allowedProjects = computed(() => {
      if (props.project) return [props.project]
      // remove projects that act as parent
      const ids = projects.value.filter((p) => p.parentId).map((p) => p.parentId)
      if (hasRight.value(Rights.PLAN_DELETE_ALL)) return projects.value.filter((p) => !ids.includes(p.id))
      return projects.value.filter((p) => !ids.includes(p.id)).filter((p) => projectsAsManager.value.includes(p.id))
    })

    const teamRepository = useTeamRepository()
    const { getAll: getAllTeams, data: teams } = teamRepository.useGetAll()

    const minDate = computed(() => selectedProject.value?.from)
    const maxDate = computed(() => selectedProject.value?.to)

    const activityRepository = useActivityRepository()
    const { getAll: getAllActivities, data: planActivities } = activityRepository.useGetAll()

    function getClosestDate(date1?: string, date2?: string, max = false) {
      if (date1 == null) return date2
      if (date2 == null) return date1
      const d1 = Date.parse(date1)
      const d2 = Date.parse(date2)
      if (max) return d1 > d2 ? date1 : date2
      return d1 < d2 ? date1 : date2
    }

    function clickClose() {
      emit('input', false)
    }

    function clickSave() {
      form.value?.validate()
      if (!formIsValid) return
      emit('input', false)
      emit('save', { ...itemInternal.value })
    }
    function onUserSelect(userId: number) {
      const user = usersBasic.value.find((user) => user.id === userId)
      userHasTeam.value = false
      if (!user) return
      userHasTeam.value = true
      itemInternal.value.teamId = user.teamId !== null ? user.teamId : null
      form.value?.validate()
    }

    const userHasTeam = ref(true)

    function onProjectSelect(projectId: number) {
      const project = projects.value.find((project) => project.id === projectId)
      if (!project) return
      itemInternal.value.from = project.from
      itemInternal.value.to = project.to
    }

    const { formatUser } = useFormatUser()

    watch(
      () => props.item,
      (value) => {
        itemInternal.value = value
        if (props.project) itemInternal.value.projectId = props.project.id
      }
    )

    watch(
      () => attrs.value,
      (value) => {
        if (!value) return
        getAllProjects({ params: { size: 2000 } })
        getUsersBasic({ page: 0, size: 2000 }, { active: true })
        getAllTeams({ params: { size: 9999 } })
        if (isOfTypeWithValue<Plan, PlanInput>(itemInternal.value, 'id')) {
          getAllActivities({ params: { planId: itemInternal.value.id } })
        }
        itemInternal.value = { ...props.item }
        if (props.project) itemInternal.value.projectId = props.project.id
        form.value?.resetValidation()
      }
    )

    return {
      form,
      formIsValid,
      selectedProject,
      minDate,
      maxDate,
      getClosestDate,
      icons: { mdiCalendar, mdiClock, mdiComment },
      validation: { required, positiveNumber, hoursBudgetValidation, planActivitiesFrom, planActivitiesTo },
      itemInternal,
      allowedProjects,
      usersBasic,
      teams,
      planActivities,
      clickSave,
      clickClose,
      isIdentifiable,
      onUserSelect,
      userHasTeam,
      onProjectSelect,
      formatUser,
    }
  },
})
