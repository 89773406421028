import { repository } from '@/plugins/repository'
import { Team, TeamInput } from '../interfaces'

export const useTeamRepository = () => {
  const version = 'v1'
  const resource = 'teams'
  const teamRepository = repository<Team, TeamInput>(version, resource)

  const dummyTeamInput = (): TeamInput => ({
    name: '',
  })

  const dummyTeam = (): Team => ({
    name: '',
    id: 0,
    size: 0,
  })

  return {
    ...teamRepository,
    dummyTeamInput,
    dummyTeam,
  }
}
