
import { computed, defineComponent, PropType, ref } from '@vue/composition-api'
import { DataTableHeader } from 'vuetify'
import { mdiPlus, mdiPencil, mdiDelete, mdiAlertCircle } from '@mdi/js'
import { Plan, Project } from '@/api/interfaces'
import { useFormat } from '@/utils/format'
import PlanDialog from '@/components/plan/plan-dialog.cmp.vue'
import ConfirmDialog from '@/components/common/confirm-dialog.vue'
import { usePlanRepository } from '@/api/repository/plan-repository'

export default defineComponent({
  name: 'plan-table',
  components: {
    PlanDialog,
    ConfirmDialog,
  },
  props: {
    items: {
      type: Array as PropType<Plan[]>,
      required: true,
    },
    create: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Function as PropType<(plan: Plan) => boolean>,
      default: () => false,
    },
    deletable: {
      type: Function as PropType<(plan: Plan) => boolean>,
      default: () => false,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    visibleProject: {
      type: Boolean,
      default: true,
    },
    defaultProject: {
      type: Object as PropType<Project | null>,
      default: null,
    },
    hideSellingPrice: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click:edit', 'click:delete', 'delete'],
  setup(props, { root, emit }) {
    const { formatCurrency, formatDateString } = useFormat()
    const { dummyPlanInput } = usePlanRepository()

    const showEdit = ref(false)
    const isNewPlan = ref(true)
    const itemToEdit = ref(dummyPlanInput())
    const showDelete = ref(false)
    const itemToDelete = ref<Plan | undefined>()

    const tableHeaders = computed<DataTableHeader[]>(() => {
      const items: DataTableHeader[] = [
        {
          text: root.$t('form.field.id').toString(),
          value: 'id',
          width: '80px',
        },
        {
          text: root.$t('form.field.project').toString(),
          value: 'projectName',
          sortable: false,
          width: '150px',
        },
        {
          text: root.$t('form.field.user').toString(),
          value: 'userFullName',
          sortable: false,
          width: '150px',
        },
        {
          text: root.$t('form.field.team').toString(),
          value: 'teamName',
          sortable: false,
          width: '150px',
        },
        {
          text: root.$t('form.field.from').toString(),
          value: 'from',
          width: '130px',
        },
        {
          text: root.$t('form.field.to').toString(),
          value: 'to',
          width: '130px',
        },
        {
          text: root.$t('form.field.hoursUsed').toString(),
          value: 'bookedHours',
          sortable: false,
          width: '200px',
        },
        {
          text: root.$t('form.field.costs').toString(),
          value: 'costs',
          width: '150px',
        },
        {
          text: root.$t('form.field.comment').toString(),
          value: 'comment',
          width: '290px',
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          width: '100px',
          align: 'end',
        },
      ]

      if (!props.hideSellingPrice) {
        items.splice(7, 0, {
          text: root.$t('form.field.sellingPrice').toString(),
          value: 'sellingPrice',
          width: '150px',
        })
      }
      if (!props.visibleProject) {
        items.splice(1, 1)
      }

      return items
    })

    function hoursProgress(plan: Plan) {
      return Math.ceil((plan.bookedHours / plan.hoursBudget) * 100)
    }

    async function clickAdd() {
      emit('click:add')
      isNewPlan.value = true
      itemToEdit.value = { ...dummyPlanInput() }
      showEdit.value = true
    }

    async function clickEdit(item: Plan) {
      emit('click:edit', { ...item })
      isNewPlan.value = false
      itemToEdit.value = { ...item }
      showEdit.value = true
    }

    async function clickDelete(item: Plan) {
      itemToDelete.value = { ...item }
      showDelete.value = true
      emit('click:delete', { ...item })
    }

    async function onDeleteConfirm() {
      if (!itemToDelete.value) return
      showDelete.value = false
      emit('delete', { ...itemToDelete.value })
    }

    return {
      icons: { mdiPlus, mdiPencil, mdiDelete, mdiAlertCircle },
      tableHeaders,
      isNewPlan,
      formatDateString,
      formatCurrency,
      clickEdit,
      showEdit,
      itemToEdit,
      clickAdd,
      clickDelete,
      showDelete,
      itemToDelete,
      onDeleteConfirm,
      hoursProgress,
    }
  },
})
