import { formatCurrency } from './format-currency'
import { formatDateString, formatDate } from './format-date'
import { formatDecimal } from './format-decimal'

export const useFormat = () => {
  return {
    formatCurrency,
    formatDateString,
    formatDate,
    formatDecimal,
  }
}
