
import { defineComponent } from '@vue/composition-api'
export default defineComponent({
  name: 'confirm-dialog',
  inheritAttrs: false,
  props: {
    item: {
      type: Object,
      default: function () {
        return {}
      },
    },
    title: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
  },
  setup(props, { emit }) {
    function onConfirm() {
      emit('input', false)
      emit('click:confirm', props.item)
      // legacy
      emit('confirm', props.item)
    }

    function onClose() {
      emit('input', false)
      // legacy
      emit('close')
    }
    return {
      onConfirm,
      onClose,
    }
  },
})
