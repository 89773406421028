var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._g(_vm._b({attrs:{"headers":_vm.tableHeaders,"items":_vm.items},scopedSlots:_vm._u([{key:"top",fn:function(){return [(!_vm.hideTitle || _vm.create)?_c('v-toolbar',{attrs:{"flat":""}},[(!_vm.hideTitle)?_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$tc('plan.title', 2))+" ")]):_vm._e(),_c('v-spacer'),(_vm.create)?_c('v-btn',{attrs:{"outlined":"","elevation":"0","color":"secondary"},on:{"click":_vm.clickAdd}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.$t('form.action.add'))+" ")])],1):_vm._e()],1):_vm._e(),_vm._t("edit",function(){return [_c('plan-dialog',_vm._g({attrs:{"item":_vm.itemToEdit,"project":_vm.defaultProject,"is-new-plan":_vm.isNewPlan},model:{value:(_vm.showEdit),callback:function ($$v) {_vm.showEdit=$$v},expression:"showEdit"}},_vm.$listeners))]},{"item":_vm.itemToEdit}),_vm._t("delete",function(){return [_c('confirm-dialog',{on:{"confirm":_vm.onDeleteConfirm},model:{value:(_vm.showDelete),callback:function ($$v) {_vm.showDelete=$$v},expression:"showDelete"}})]},{"item":_vm.itemToDelete})]},proxy:true},{key:"item.from",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateString(item.from))+" ")]}},{key:"item.to",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateString(item.to))+" ")]}},{key:"item.bookedHours",fn:function(ref){
var item = ref.item;
return [_c('v-progress-linear',{attrs:{"height":"25","value":_vm.hoursProgress(item).toFixed(2)},scopedSlots:_vm._u([{key:"default",fn:function(){return [_vm._v(" "+_vm._s(item.bookedHours)+" / "+_vm._s(item.hoursBudget)+" ")]},proxy:true}],null,true)})]}},{key:"item.sellingPrice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(item.sellingPrice))+" ")]}},{key:"item.costs",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(item.costs))+" ")]}},{key:"item.comment",fn:function(ref){
var item = ref.item;
return [(item.comment !== null && item.comment.length > 99)?_c('td',[_vm._v(_vm._s(item.comment.substr(0, 100))+"... ")]):_c('td',[_vm._v(_vm._s(item.comment))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.corrupt)?_c('v-tooltip',{attrs:{"color":"error","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiAlertCircle)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.corrupt ? item.corruptMessages.join(' & ') : ''))])]):_vm._e(),(_vm.editable(item))?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.clickEdit(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")]):_vm._e(),(_vm.deletable(item))?_c('v-icon',{attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.clickDelete(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")]):_vm._e()]}}],null,true)},'v-data-table',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }