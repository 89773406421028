
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'common-autocomplete',
  inheritAttrs: false,
  props: {},
  setup: (_, { emit }) => {
    function endIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        emit('scroll-end')
      }
    }

    return {
      endIntersect,
    }
  },
})
