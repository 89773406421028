import { User, UserBasic } from '@/api/users'

export function formatUser(user: User | UserBasic) {
  return `${user.firstName} ${user.lastName}`
}

export function useFormatUser() {
  return {
    formatUser,
  }
}
