import { repository } from '@/plugins/repository'
import { Plan, PlanInput } from '@/api/interfaces'

export const usePlanRepository = () => {
  const version = 'v1'
  const resource = 'plans'
  const planRepository = repository<Plan, PlanInput>(version, resource)

  const dummyPlanInput = (): PlanInput => ({
    costs: 0,
    from: new Date().toISOString().split('T')[0],
    hoursBudget: 0,
    hoursStepSize: 0,
    projectId: null,
    sellingPrice: 0,
    to: new Date().toISOString().split('T')[0],
    userId: null,
    teamId: null,
    billable: true,
  })

  const dummyPlan = (): Plan => ({
    bookedHours: 0,
    costs: 0,
    from: new Date().toISOString().split('T')[0],
    hoursBudget: 0,
    hoursStepSize: 0,
    id: 0,
    projectId: 0,
    projectName: '',
    sellingPrice: 0,
    to: new Date().toISOString().split('T')[0],
    userId: 0,
    teamId: 0,
    teamName: '',
    billable: true,
    corrupt: false,
    corruptMessages: [],
  })

  return {
    ...planRepository,
    dummyPlanInput,
    dummyPlan,
  }
}
