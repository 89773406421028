
import { defineComponent, ref, watch } from '@vue/composition-api'
import { isAfter, isBefore } from 'date-fns'
import { VTextField } from 'vuetify/lib'

export default defineComponent({
  name: 'date-input',
  extends: VTextField,
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: null,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    disable: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { root, emit }) {
    const show = ref(false)
    const valueInternal = ref('')
    const dateFormatted = ref('')

    function onChangeDatePicker(ymd: string) {
      show.value = false
      const date = parseYmdDateString(ymd)
      if (!isValidDate) return
      setDateFormatted(date)
      const input = date.toISOString().split('T')[0]
      emit('input', input)
    }

    function onChangeText(dmy: string) {
      if (validateDateString(dmy) !== true) return
      const date = parseDmyString(dmy)
      const input = date.toISOString().split('T')[0]
      emit('input', input)
    }

    function isValidDate(input: Date) {
      return isNaN(input.getTime()) ? false : true
    }

    function validateDateString(input: string) {
      if (input === '') return false
      const isValidString = /^([0-9]{2}\.[0-9]{2}\.[0-9]{4})$/gm.test(input)
      if (!isValidString) return root.$t('form.validate.date.format').toString()
      const date = parseDmyString(input)
      // check if date is valid
      return isNaN(date.getTime()) ? root.$t('form.validate.date.error').toString() : true
    }

    function parseDmyString(input: string) {
      const [year, month, day] = input
        .split('.')
        .reverse()
        .map((s) => Number(s))
      const date = new Date(Date.UTC(year, month - 1, day))
      return date
    }

    function parseYmdDateString(input: string) {
      const [year, month, day] = input.split('-').map((s) => Number(s))
      const date = new Date(Date.UTC(year, month - 1, day))
      return date
    }

    function setDateFormatted(input: Date) {
      const formatter = Intl.DateTimeFormat('de', { year: 'numeric', month: '2-digit', day: '2-digit' })
      dateFormatted.value = formatter.format(input)
    }

    watch(
      () => props.value,
      (current) => {
        valueInternal.value = current
        if (current === '' || current == null) {
          dateFormatted.value = ''
          return
        }
        const date = parseYmdDateString(current)
        setDateFormatted(date)
      },
      { immediate: true }
    )

    function disableWeekends(date: string) {
      const day = new Date(date).getDay()
      return day !== 0 && day !== 6
    }

    function validateWeekends(dateString: string) {
      const day = parseDmyString(dateString).getDay()
      return (day !== 0 && day !== 6) || root.$t('form.field.date')
    }

    function validateBorders(dateString: string) {
      if (dateString === '' || dateString == null) return true
      let valid = true
      const date = parseDmyString(dateString)

      if (props.options.min && props.options.min != null && isBefore(date, parseYmdDateString(props.options.min))) {
        return root.$t('date.error.toEarly')
      }
      if (props.options.max && props.options.max != null && isAfter(date, parseYmdDateString(props.options.max))) {
        return root.$t('date.error.toSoon')
      }

      return valid || root.$t('form.validation.date.error').toString()
    }

    return {
      show,
      valueInternal,
      dateFormatted,
      onChangeText,
      onChangeDatePicker,
      validateDateString,
      disableWeekends,
      validateWeekends,
      validateBorders,
    }
  },
})
