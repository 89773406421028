
import { useAuthGetters } from '@/store'
import { computed, defineComponent, ref, watch, nextTick } from '@vue/composition-api'

export default defineComponent({
  name: 'currency-input',
  props: {
    value: {
      type: Number,
      default: 0,
    },
    disable: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const valueInternal = ref('')
    const { currentUser } = useAuthGetters()
    const locale = computed(() => currentUser.value.locale)

    const onInput = (input?: string) => {
      // catch trigger on validation
      if (input == null) return
      valueInternal.value = input
      nextTick(() => {
        // only allow numbers
        valueInternal.value = input.replace(/[^0-9 ]/gi, '')
        emit('input', Number(valueInternal.value) / 100)
      })
    }

    const formatter = computed(
      () => new Intl.NumberFormat(locale.value, { maximumFractionDigits: 2, minimumFractionDigits: 2 })
    )

    const valueDisplay = computed(() => {
      return formatter.value.format(Number(valueInternal.value) / 100)
    })

    watch(
      () => props.value,
      (current) => {
        valueInternal.value = String(current * 100)
      },
      { immediate: true }
    )

    return {
      valueInternal,
      valueDisplay,
      onInput,
    }
  },
})
