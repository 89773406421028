
import { computed, defineComponent, reactive, ref, watch } from '@vue/composition-api'
import { mdiPlus, mdiCheck, mdiClose, mdiPencil, mdiDelete } from '@mdi/js'
import { Rights } from '@/api/rights'
import { useAuthGetters } from '@/store/modules/auth/useAuthModule'
import { usePagination } from '@/utils/pagination'
import planTable from '@/components/plan/plan-table.cmp.vue'
import CommonAutocomplete from '@/components/common/common-autocomplete.vue'
import { useGetUsersBasic, User } from '@/api/users'
import { Plan, PlanInput } from '@/api/interfaces'
import { useNotify } from '@/store/modules/notify/useNotifyModule'
import { usePlanRepository } from '@/api/repository/plan-repository'
import { useProjectRepository } from '@/api/repository/project-repository'
import { debounce } from 'lodash'

export default defineComponent({
  name: 'plan-overview',
  components: {
    planTable,
    CommonAutocomplete,
  },
  setup(props, { root }) {
    const { vuetifyTableOptions, paginationParams } = usePagination()
    vuetifyTableOptions.value.sortBy = ['id']
    vuetifyTableOptions.value.sortDesc = [true]

    const { addNotification } = useNotify()

    const { currentUser } = useAuthGetters()

    const planRepository = usePlanRepository()
    const { getAll: getAllPlans, data: plans, isLoading, total: totalPlans } = planRepository.useGetAll()

    const projectRepository = useProjectRepository()
    const {
      getAll: getAllProjects,
      data: projects,
      paginationResponse: projectPaginationResponse,
    } = projectRepository.useGetAll()

    const filters: { userId?: number; projectId?: number; dateFrom?: string; dateTo?: string } = reactive({
      userId: undefined,
      projectId: undefined,
      dateFrom: undefined,
      dateTo: undefined,
    })

    const activeYear = ref(new Date().getFullYear())
    setYear(activeYear.value)
    const years = computed(() => {
      const years: { text: number; value: number }[] = []
      const currentYear = new Date().getFullYear()
      for (let i = 2017; i <= currentYear; i++) {
        years.push({ text: i, value: i })
      }
      return years
    })

    const editIsActive = ref(false)
    const planToEdit = ref<PlanInput>({ ...planRepository.dummyPlanInput() })

    const { isProjectManager, projectsAsManager, hasRight } = useAuthGetters()
    const canCreatePlan = computed(() => hasRight.value(Rights.PLAN_CREATE_ALL) || isProjectManager.value)
    const showUserFilter = computed(
      () => hasRight.value(Rights.USER_READ) && (hasRight.value(Rights.PLAN_READ_ALL) || isProjectManager.value)
    )
    const showProjectFilter = computed(() => hasRight.value(Rights.PROJECT_READ))

    const { getUsersBasic, data: users, paginationResponse: userPaginationResponse } = useGetUsersBasic()

    async function updatePlans() {
      getAllPlans({ params: { ...paginationParams.value, ...filters } })
    }

    async function onSavePlans(plan: Plan) {
      const { save: savePlan } = planRepository.useSave()
      await savePlan(plan)
      addNotification({ text: root.$t('form.save.success').toString(), type: 'success' })
      updatePlans()
      editIsActive.value = false
    }

    async function onDeletePlan(plan: Plan) {
      const { remove: removePlan } = planRepository.useRemove()
      await removePlan(plan.id)
      addNotification({ text: root.$t('form.delete.success').toString(), type: 'success' })
      updatePlans()
    }

    function setYear(year?: number) {
      if (year === null) {
        filters.dateFrom = undefined
        filters.dateTo = undefined
      } else {
        filters.dateFrom = `${year}-01-01`
        filters.dateTo = `${year}-12-31`
      }
    }

    function canUpdatePlan(plan: Plan) {
      return hasRight.value(Rights.PLAN_CREATE_ALL) || projectsAsManager.value.includes(plan.projectId)
    }

    function canDeletePlan(plan: Plan) {
      return hasRight.value(Rights.PLAN_DELETE_ALL) || projectsAsManager.value.includes(plan.projectId)
    }

    const canReadSellingPrice = computed(() => hasRight.value(Rights.PLAN_READ_SELLING_PRICE))

    const userSearchInput = ref('')

    // if user cannot create new plans limit results
    const projectManagerIds = computed(() =>
      hasRight.value(Rights.PLAN_CREATE_ALL) ? undefined : [currentUser.value.id]
    )
    const debounceUserSearch = debounce(
      (name) => getUsersBasic({ page: 0, size: 15 }, { name, projectManagerIds: projectManagerIds.value }),
      500
    )
    watch(userSearchInput, (name) => debounceUserSearch(name))

    const projectSearchInput = ref('')
    const debounceProjectSeach = debounce(
      (name) =>
        // use userIds instead of projectManagerIds to include the user itself
        getAllProjects({
          params: { name, userIds: projectManagerIds.value, size: 15 },
        }),
      500
    )
    watch(projectSearchInput, (name) => debounceProjectSeach(name))

    const getUserFullName = (user: User) => {
      return `${user.firstName} ${user.lastName}`
    }

    watch(activeYear, (activeYear) => {
      setYear(activeYear)
    })

    watch(filters, () => {
      updatePlans()
    })

    const filterPageCounter = ref<number>(1)

    function loadProjects() {
      if (filterPageCounter.value <= projectPaginationResponse.value.totalPages) {
        getAllProjects({ params: { page: filterPageCounter.value++, size: 15 } })
      }
    }

    function loadUser() {
      if (filterPageCounter.value <= userPaginationResponse.value.totalPages) {
        getUsersBasic({ page: filterPageCounter.value++, size: 15 })
      }
    }

    function resetFilterPageCounter() {
      filterPageCounter.value = 1
    }

    return {
      filters,
      showUserFilter,
      showProjectFilter,
      activeYear,
      years,
      icons: { mdiPlus, mdiCheck, mdiClose, mdiPencil, mdiDelete },
      canReadSellingPrice,
      canCreatePlan,
      canUpdatePlan,
      canDeletePlan,
      totalPlans,
      users,
      projects,
      isLoading,
      updatePlans,
      plans,
      onSavePlans,
      onDeletePlan,
      planToEdit,
      editIsActive,
      vuetifyTableOptions,
      userSearchInput,
      projectSearchInput,
      getUserFullName,
      loadProjects,
      loadUser,
      resetFilterPageCounter,
    }
  },
})
