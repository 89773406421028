import { repository } from '@/plugins/repository'
import { Activity, ActivityInput } from '../interfaces'

export const useActivityRepository = () => {
  const version = 'v1'
  const resource = 'activities'
  const activityRepository = repository<Activity, ActivityInput>(version, resource)

  const dummyActivityInput = (): ActivityInput => ({
    comment: '',
    date: new Date().toISOString().split('T')[0],
    hours: 0,
    planId: 0,
    userId: 0,
  })

  const dummyActivity = (): Activity => ({
    comment: '',
    date: new Date().toISOString().split('T')[0],
    hours: 0,
    planId: 0,
    id: 0,
    projectId: 0,
    projectName: '',
    userId: 0,
  })

  return {
    ...activityRepository,
    dummyActivityInput,
    dummyActivity,
  }
}
