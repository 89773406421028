interface FormatDateStringOptions {
  locale?: string
  format?: Intl.DateTimeFormatOptions
}

export function formatDate(date: Date, options?: FormatDateStringOptions) {
  const defaults: FormatDateStringOptions = {
    locale: 'de',
    format: { day: '2-digit', month: '2-digit', year: 'numeric' },
  }
  const { locale, format } = { ...defaults, ...options }
  return date.toLocaleDateString(locale, format)
}

export function formatDateString(dateString: string, options?: FormatDateStringOptions) {
  if (dateString == null) return ''
  const [year, month, day] = dateString.split('-').map((s) => Number(s))
  const date = new Date(year, month - 1, day)
  return formatDate(date, options)
}
