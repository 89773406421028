import { computed, ref } from '@vue/composition-api'

import { DataOptions } from 'vuetify'

export interface PaginationParams {
  page: number
  size: number
  sort?: string | string[]
}

export interface SortParams {
  sorted: boolean
  unsortet: boolean
  empty: boolean
}

export interface PaginationResponse {
  empty: boolean
  first: boolean
  last: boolean
  number: number
  numberOfElements: number
  pageable: {
    offset: number
    pageNumber: number
    pageSize: number
    paged: boolean
    sort: SortParams
    unpaged: boolean
  }
  size: number
  sort: SortParams
  totalElements: number
  totalPages: number
}

export interface Page<T> extends PaginationResponse {
  content: T[]
}

export interface VuetifyTableOptions {
  page: number
  itemsPerPage: number
  sortBy?: string[]
  sortDesc?: boolean[]
  groupBy?: string[]
  groupDesc?: boolean[]
  multiSort?: boolean
  mustSort?: boolean
}

export const usePagination = (
  initialPaginationParams: DataOptions = {
    page: 1,
    itemsPerPage: 15,
    sortBy: ['id'],
    sortDesc: [false],
    groupBy: [],
    groupDesc: [],
    multiSort: false,
    mustSort: true,
  }
) => {
  const vuetifyTableOptions = ref<DataOptions>(initialPaginationParams)

  const getPaginationParams = (): PaginationParams => {
    return {
      page: vuetifyTableOptions.value.page - 1,
      size: vuetifyTableOptions.value.itemsPerPage >= 0 ? vuetifyTableOptions.value.itemsPerPage : 9001,
      sort: `${vuetifyTableOptions.value.sortBy?.[0]},${
        vuetifyTableOptions.value.sortDesc ? (vuetifyTableOptions.value.sortDesc[0] ? 'desc' : 'asc') : ''
      }`,
    }
  }

  const paginationParams = computed(() => ({
    page: vuetifyTableOptions.value.page - 1,
    size: vuetifyTableOptions.value.itemsPerPage >= 0 ? vuetifyTableOptions.value.itemsPerPage : 9001,
    sort: vuetifyTableOptions.value.sortBy.map(
      (value, index) => `${value},${vuetifyTableOptions.value.sortDesc[index] ? 'desc' : 'asc'}`
    ),
  }))

  return {
    vuetifyTableOptions,
    getPaginationParams,
    paginationParams,
  }
}
