import { useAxios, useAxiosHateoasPaginated } from '@/plugins/axios'
import { repository } from '@/plugins/repository'
import { AxiosRequestConfig } from 'axios'
import { Invoice, Project, ProjectDetails, ProjectInput } from '@/api/interfaces'
import { computed } from '@vue/composition-api'
import { useAuthGetters } from '@/store'
import { Rights } from '../rights'

export const useProjectRepository = () => {
  const version = 'v1'
  const resource = 'projects'
  const projectRepository = repository<Project, ProjectInput>(version, resource)

  const useGetProjectStatus = () => {
    const client = useAxios({
      method: 'get',
      url: `${version}/${resource}`,
    })
    const getProjectStatus = (cfg: AxiosRequestConfig = {}) =>
      client.exec({ ...cfg, url: `${version}/${resource}/status` })

    return {
      ...client,
      getProjectStatus,
    }
  }

  const useGetDetails = () => {
    const client = useAxios<ProjectDetails>({
      method: 'get',
      url: `${version}/${resource}`,
    })
    const getDetails = (id: number, cfg: AxiosRequestConfig = {}) =>
      client.exec({ ...cfg, url: `${version}/${resource}/${id}/info` })

    return {
      ...client,
      getDetails,
    }
  }

  const useGetProjectInvoices = () => {
    const client = useAxiosHateoasPaginated<Invoice>({
      method: 'get',
      url: `${version}/${resource}`,
    })
    const getProjectInvoices = (id: number, cfg: AxiosRequestConfig = {}) =>
      client.exec({ ...cfg, url: `${version}/${resource}/${id}/invoices` })

    return {
      ...client,
      getProjectInvoices,
    }
  }

  const useGetAllForUser = () => {
    const { getAll, ...rest } = projectRepository.useGetAll()

    const { hasRight, currentUser } = useAuthGetters()

    const projectManagerIds = computed(() =>
      hasRight.value(Rights.PLAN_CREATE_ALL) ? undefined : [currentUser.value.id]
    )

    const getAllForUser = (cfg: AxiosRequestConfig = {}) => {
      const params = { ...(cfg.params || {}), projectManagerIds: projectManagerIds.value }
      getAll({ ...cfg, params })
    }

    return {
      getAllForUser,
      ...rest,
    }
  }

  const dummyProjectInput = (): ProjectInput => ({
    clientId: 0,
    name: '',
    from: new Date().toISOString().split('T')[0],
    to: new Date().toISOString().split('T')[0],
    parentId: null,
    parentName: null,
    projectManagerId: 0,
    comment: '',
    hoursStepSize: 0.25,
    color: '',
    budget: 0,
    bookedHours: 0,
    projectStatus: 'ACTIVE',
  })

  const dummyProject = (): Project => ({
    bookedHours: 0,
    budget: 0,
    clientId: 0,
    color: '',
    comment: '',
    from: new Date().toISOString().split('T')[0],
    hoursStepSize: 0,
    id: 0,
    name: '',
    parentId: null,
    parentName: null,
    projectManagerId: 0,
    projectManagerFullName: '',
    to: new Date().toISOString().split('T')[0],
    projectStatus: 'ACTIVE',
    corrupt: false,
    corruptMessages: [],
  })

  return {
    ...projectRepository,
    useGetProjectStatus,
    useGetProjectInvoices,
    useGetDetails,
    useGetAllForUser,
    dummyProjectInput,
    dummyProject,
  }
}
