import { useAxios, useAxiosPaginated } from '@/plugins/axios'
import { PaginationParams } from '@/utils/pagination'

import { UserRight } from './rights'
import { UserRole } from './roles'

const version = 'v1'
const resource = 'users'

export interface User {
  id: number
  username: string
  updatedBy: string
  updatedAt: string
  enabled: boolean
  email: string
  firstName: string
  lastName: string
  locale: string
  phone: string
  mobile: string
  roles: UserRole<UserRight>[]
  accountNonExpired: boolean
  accountNonLocked: boolean
  credentialsNonExpired: boolean
  personnelNumbers: string[]
  teamId?: number
  teamName?: string
  price?: number
  loginDisabled: boolean
  source: string
}

export type UserBasic = Pick<User, 'id' | 'firstName' | 'lastName' | 'price' | 'teamName' | 'teamId'> & {
  roles: UserRole<UserRight>[] | null
  teamId: number | null
}

export interface UserUpdate {
  username?: string
  email?: string
  firstName?: string
  lastName?: string
  locale?: string
  phone?: string
  mobile?: string
  enabled?: boolean
  roles?: string[]
  password?: string
  personnelNumbers?: string[]
  teamId?: number
  price?: number
  loginDisabled?: boolean
}

export interface UserParams {
  roles?: string[]
  teamId?: number
  name?: string
  projectIds?: number[]
  projectManagerIds?: number[]
  active?: boolean
}

export const useGetUsers = () => {
  const axios = useAxiosPaginated<User>({
    method: 'get',
    url: `${version}/${resource}`,
  })

  const getUsers = (paginationParams?: PaginationParams, params?: UserParams) =>
    axios.exec({ params: { ...paginationParams, ...params } })

  return {
    ...axios,
    getUsers,
  }
}

export const useGetUsersBasic = () => {
  const axios = useAxiosPaginated<UserBasic>({
    method: 'get',
    url: `${version}/${resource}/basic`,
  })

  const getUsersBasic = (paginationParams?: PaginationParams, params?: UserParams) =>
    axios.exec({ params: { ...paginationParams, ...params } })

  return {
    ...axios,
    getUsersBasic,
  }
}

export const useGetUser = () => {
  const axios = useAxios<User>({
    method: 'get',
  })

  const getUser = (userId: string | number) => axios.exec({ url: `${version}/${resource}/${userId}` })

  return {
    ...axios,
    getUser,
  }
}
export const useCreateUser = () => {
  const axios = useAxios<User>({
    method: 'post',
    url: `${version}/${resource}`,
  })

  const createUser = (user: UserUpdate) => axios.exec({ data: user })

  return {
    ...axios,
    createUser,
  }
}
export const useUpdateUser = () => {
  const axios = useAxios<User>({
    method: 'put',
  })

  const updateUser = (userId: number, user: UserUpdate) =>
    axios.exec({ url: `${version}/${resource}/${userId}`, data: user })

  return {
    ...axios,
    updateUser,
  }
}
export const useDeleteUser = () => {
  const axios = useAxios<void>({
    method: 'delete',
  })

  const deleteUser = (userId: number) => axios.exec({ url: `${version}/${resource}/${userId}` })

  return {
    ...axios,
    deleteUser,
  }
}

export const useInvalidateToken = () => {
  const axios = useAxios<void>({ method: 'POST' })

  const invalidateToken = (userId: number) => axios.exec({ url: `${version}/${resource}/${userId}/terminate` })

  return {
    ...axios,
    invalidateToken,
  }
}

export const emptyUser = (): User => ({
  id: 0,
  username: '',
  updatedBy: '',
  updatedAt: '',
  enabled: true,
  email: '',
  firstName: '',
  lastName: '',
  locale: '',
  phone: '',
  mobile: '',
  roles: [{ protected: false, name: '', rights: [], editable: false, defaultRole: false, systemRole: false }],
  accountNonExpired: true,
  accountNonLocked: true,
  credentialsNonExpired: true,
  personnelNumbers: [],
  loginDisabled: false,
  source: '',
})
